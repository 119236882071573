import { CSSProperties, useEffect } from 'react';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { PageProps } from '@hultafors/snickers/types';

export { reportWebVitals } from '@hultafors/shared/helpers';

const ApplicationProvider = dynamic(() =>
  import('@hultafors/snickers/context').then((mod) => mod.ApplicationProvider),
);

const Footer = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Footer),
);

const Fonts = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Fonts),
);

const GlobalStyle = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.GlobalStyle),
);

const Header = dynamic(() =>
  import('@hultafors/snickers/components').then((mod) => mod.Header),
);

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const {
    global,
    allMenuItems,
    menuExtra,
    footer,
    settings,
    sizeGuide,
    workwearGuide,
    featureToggle,
  } = pageProps;

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  const wrapperStyle: CSSProperties = {
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <GlobalStyle />

      <ApplicationProvider
        value={{
          global,
          allMenuItems,
          menuExtra,
          footer,
          settings,
          sizeGuide,
          workwearGuide,
          featureToggle,
        }}
      >
        <Fonts style={wrapperStyle}>
          {global && <Header />}
          <Component {...pageProps} />
          <GoogleTagManager gtmId="GTM-P5KFSP" />
          {footer && <Footer />}
          <div id="portal" />
        </Fonts>
      </ApplicationProvider>
    </>
  );
};

export default CustomApp;
